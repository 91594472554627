import React from 'react';
import {useSelector} from 'react-redux';
import {NavLink} from 'react-router-dom';
import styled from 'styled-components';
import images from '../config/images';


const NavWrapper = styled.div`
    background-color: ${props => props.theme.lightgray};
    width: ${props => props.theme.scale * 162}px;
    flex: 0 0 auto;
    position: relative;
    overflow: hidden;

    ${props => (props.theme.colorTheme === 'dark') ? `background-color: ${props.theme.darkShade2};` : `background-color: ${props.theme.white};`}
`;

const NavItem = styled(NavLink)`
    margin: ${props => props.theme.scale * 20}px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-family: 'MuseoSans-700';
    font-size: ${props => props.theme.scale * 20}px;
    text-decoration: none;    

    &:first-child {
        margin-top: ${props => props.theme.scale * 40}px;
    }

    h3 {
        ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
    }

    &.active {

        div {
            background: ${props => props.theme.white}; 
        }

        svg path, svg line {
            stroke: ${props => props.theme.shade3}; 
            fill: ${props => props.theme.shade3}; 
        }
    }
`;

const NavIcon = styled.div`
    text-align: center;
    background-color: ${props => props.theme.shade3};
    width: 60%;
    border-radius: 500px; 
    margin: 0 auto;
    height: ${props => props.theme.scale * 130}px; 
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 58%;
    }

    svg path, svg line {
        stroke: ${props => props.theme.white}; 
        fill: ${props => props.theme.white}; 
    }
`;

const Copyright = styled.div`
    position: absolute;
    bottom: 15px;
    left: 15px;
    font-size: ${props => props.theme.scale * 9}px;
    ${props => (props.theme.colorTheme === 'dark') ? `color: ${props.theme.white};` : `color: ${props.theme.black};`}
`;

const TouchIconWrapper = styled.div`
    position: absolute;
    bottom: 200px;
    left: -48px;
    opacity: 0.1;
    width: ${props => props.theme.scale * 162}px;
    height: ${props => props.theme.scale * 162}px;
`;

const Nav = () => {
    const navItems = useSelector(state => state.config.navigation);
    const customer = useSelector(state => state.config.customer);

    if(!navItems) return(null);

    const handleClick = (e,item) => {
        console.log("CLICKED");
    };

    let TouchIcon = images.icons.touch;

    return(
        <NavWrapper>
            {navItems.map((item, i) => {
                let Icon = images.icons[item.id];

                return(
                    <React.Fragment key={i}>
                    {item.status === 2 &&
                        <NavItem to={`/${item.id}`} key={item.id} className={(navData) => navData.isActive ? "active" : "" }  onClick={(e) => handleClick(e,item)}>
                            <NavIcon>
                                <Icon />
                            </NavIcon>
                            <h3>{item.label}</h3>
                        </NavItem>
                    }
                    </React.Fragment>
                );
            })}
            <TouchIconWrapper>
                <TouchIcon />
            </TouchIconWrapper>
            {customer !== 'publismart' && 
                <Copyright>
                SOLUTION<br/>
                DESIGNED AND PROVIDED<br/>
                BY NICHE GUARDIAN
                </Copyright>
            }
        </NavWrapper>
    )
}

export default Nav;