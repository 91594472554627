const themes = {
    global: {
        black: '#000000',
        white: '#ffffff',
        darkgray: '#e5e5e5',
        lightgray: '#f2f2f2',
        darkShade1: '#262626',
        darkShade2: '#404040'
    },
    publismart: {
        shade1: '#18a4e0',
        shade2: '#384041',
        shade3: '#48ad8b',
        place: '#48ad8b',
        logo: 'logo_smart-mobility.svg',
        marker: 'ic_marker_publismart.svg'
    },
    dudelange: {
        shade1: '#e53324',
        shade2: '#871c21',
        shade3: '#c72930',
        place: '#e53324',
        logo: 'logo_dudelange.svg'
    },
    rumelange: {
        shade1: '#a81c36',
        shade2: '#a81c36',
        shade3: '#a81c36',
        place: '#a81c36',
        logo: 'logo_rumelange.svg'
    },
    diekirch: {
        shade1: '#D8232A',
        shade2: '#A19556',
        shade3: '#278DCB',
        place: '#D8232A',
        logo: 'logo_diekirch.svg'
    },
    erpeldange: {
        shade1: '#f28500',
        shade2: '#00a3e3',
        shade3: '#3bab36',
        place: '#00a3e3',
        logo: 'logo_erpeldange-sur-sure.svg'
    },
    hesperange: {
        shade1: '#ed1c24',
        shade2: '#ed1c24',
        shade3: '#25408f',
        place: '#ed1c24',
        logo: 'logo_hesperange.svg'
    },
    bettendorf: {
        shade1: '#ed0039',
        shade2: '#959da7',
        shade3: '#8f7e43',
        place: '#ed1c24',
        logo: 'logo_hesperange.svg'
    },
};

export default themes;
